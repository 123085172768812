import * as React from "react"

import { Alert, Card, Button, DropdownButton, MenuItem, Modal } from "../../wrappers"
import { currentDatabaseRef } from "../../../config/constants"
import { StripedTable } from "../../../components/StripedTable"
import * as _ from "lodash"
import { RuntimeIntegrationEdit, AdditionalElement, AdditionalElementType } from "./RuntimeIntegrationEdit"
import { RoleProps, withRole } from '../../../routes';
import { child, get, off, onValue, remove } from "firebase/database"

interface RuntimeIntegrationDeleteRequest {
    type: string
    id: string
}

interface RuntimeIntegrationsState {
    addIntegrationType?: RuntimeIntegrationType
    editIntegrationId?: string
    existingIntegrations?: any
    configurations?: any
    deleteRequest?: RuntimeIntegrationDeleteRequest
    copied: boolean
    integrations: _.Dictionary<any>
}

export enum RuntimeIntegrationType {
    customerLookup = "customer_lookup_integrations",
    customerDiscountLookup = "customer_discount_lookup_integrations",
    productLookup = "product_lookup_integrations",
    shipping = "shipping_integrations"
}

export function integrationName(type: RuntimeIntegrationType): string {
    switch (type) {
        case RuntimeIntegrationType.shipping:
            return "Shipping"
        case RuntimeIntegrationType.customerLookup:
            return "Customer lookup"
        case RuntimeIntegrationType.customerDiscountLookup:
            return "Customer discount lookup"
        case RuntimeIntegrationType.productLookup:
            return "Product lookup (stock app)"
    }
}

function additionalElements(type: RuntimeIntegrationType): AdditionalElement[] {
    switch (type) {
        case RuntimeIntegrationType.shipping:
            return [{
                name: "Accepted country codes",
                placeholder: "Please enter a comma separated list of the country codes for which shipping is allowed",
                type: AdditionalElementType.list,
                propertyName: "accepted_country_codes"
            }]
        case RuntimeIntegrationType.customerLookup:
            return []
        case RuntimeIntegrationType.customerDiscountLookup:
            return []
        case RuntimeIntegrationType.productLookup:
            return []
    }
}

function staticElements(type: RuntimeIntegrationType): AdditionalElement[] | undefined {
    switch (type) {
        case RuntimeIntegrationType.shipping:
            return [
                {
                    name: "ID",
                    placeholder: "Please enter an identifier for this item",
                    type: AdditionalElementType.text,
                    propertyName: "id"
                },
                {
                    name: "Title",
                    placeholder: "The title of the shipping item",
                    type: AdditionalElementType.text,
                    propertyName: "title"
                },
                {
                    name: "Sub title",
                    placeholder: "A sub title for the shipping item",
                    type: AdditionalElementType.text,
                    propertyName: "subtitle"
                },
                {
                    name: "Price",
                    placeholder: "The price for this type of shipping item",
                    type: AdditionalElementType.currencyAmount,
                    propertyName: "price"
                }
            ]
        case RuntimeIntegrationType.customerLookup:
            return undefined
        case RuntimeIntegrationType.customerDiscountLookup:
            return undefined
        case RuntimeIntegrationType.productLookup:
            return undefined
    }
}

export enum RuntimeIntegrationIdentification {
    byIdentifier, byPriority
}

export function allowsParameters(type: RuntimeIntegrationType): boolean {
    switch (type) {
        case RuntimeIntegrationType.shipping:
            return false
        case RuntimeIntegrationType.customerLookup:
            return true
        case RuntimeIntegrationType.customerDiscountLookup:
            return true
        case RuntimeIntegrationType.productLookup:
            return true
    }
}

export function runtimeIntegrationIdentification(type: RuntimeIntegrationType): RuntimeIntegrationIdentification {
    switch (type) {
        case RuntimeIntegrationType.shipping:
            return RuntimeIntegrationIdentification.byIdentifier
        case RuntimeIntegrationType.customerLookup:
            return RuntimeIntegrationIdentification.byPriority
        case RuntimeIntegrationType.customerDiscountLookup:
            return RuntimeIntegrationIdentification.byPriority
        case RuntimeIntegrationType.productLookup:
            return RuntimeIntegrationIdentification.byPriority
    }
}

function availableRuntimeIntegrations(): RuntimeIntegrationType[] {
    return [
        RuntimeIntegrationType.shipping,
        RuntimeIntegrationType.customerLookup,
        RuntimeIntegrationType.customerDiscountLookup,
        RuntimeIntegrationType.productLookup
    ]
}

class RuntimeIntegrations extends React.Component<RoleProps, RuntimeIntegrationsState> {
    constructor(props: RoleProps) {
        super(props)

        this.state = {
            addIntegrationType: undefined,
            copied: false,
            integrations: {}
        }
    }

    async componentDidMount() {
        const account = this.props.role.account_id
        const accountRef = child(currentDatabaseRef(), `v1/accounts/${account}`)
        const configRef = child(accountRef, "configuration")

        const initialIntegrations: any = {}
        for (const integration of availableRuntimeIntegrations()) {
            const integrationRef = child(configRef, integration)
            const snap = await get(integrationRef)
            const integrationData = snap.val()
            if (integrationData === null) { continue }
            initialIntegrations[integration] = integrationData
        }
        this.setState({ integrations: initialIntegrations })

        for (const integration of availableRuntimeIntegrations()) {
            const integrationRef = child(configRef, integration)

            onValue(integrationRef, configSnap => {
                const integrations: _.Dictionary<any> = _.cloneDeep(this.state.integrations)
                integrations[integration] = configSnap.val()
                this.setState({ integrations: integrations })
            })
        }

    }

    componentWillUnmount() {
        const account = this.props.role.account_id
        const accountRef = child(currentDatabaseRef(), `v1/accounts/${account}`)
        const configRef = child(accountRef, "configuration")

        for (const integration of availableRuntimeIntegrations()) {
            const integrationRef = child(configRef, integration)
            off(integrationRef, "value")
        }
    }

    async deleteIntegration(deleteRequest?: RuntimeIntegrationDeleteRequest) {
        if (deleteRequest === undefined) { return }
        const account = this.props.role.account_id
        const accountRef = child(currentDatabaseRef(), `v1/accounts/${account}`)
        const integrationRef = child(child(child(accountRef, "configuration"), deleteRequest.type), deleteRequest.id)
        await remove(integrationRef)

        this.setState({ deleteRequest: undefined })
    }

    renderExistingIntegrations() {
        const integrationTypes = this.state.integrations

        return availableRuntimeIntegrations().map(integrationType => {
            const integrations = integrationTypes[integrationType]
            if (integrations === undefined) { return null }

            return (
                <Card className="my-4" key={integrationType}>
                    <Card.Header>{integrationName(integrationType)}</Card.Header>
                    <Card.Body>
                        <StripedTable>
                            <thead>
                                <tr>
                                    <th>Integration Id</th>
                                    <th>Integration Name</th>
                                    <th>Endpoint</th>
                                    <th>Edit</th>
                                    <th>Delete</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.keys(integrations || {}).map(integrationKey => {
                                    const integration = integrations[integrationKey]
                                    let name = integration.name || "-"
                                    if (_.isObject(name)) {
                                        name = name[Object.keys(name)[0]]
                                    }

                                    return (
                                        <tr key={integrationKey}>
                                            <td>{integrationKey}</td>
                                            <td>{name}</td>
                                            <td style={{ wordBreak: "break-all" }}>{(integration.request || {}).url || "-"}</td>
                                            <td className="narrow"><Button variant="success" onClick={(event) => { event.stopPropagation(); this.setState({ editIntegrationId: integrationKey, addIntegrationType: integrationType }) }}>Edit</Button></td>
                                            <td className="narrow">
                                                <Button variant="danger" onClick={(event) => { event.stopPropagation(); this.setState({ deleteRequest: { id: integrationKey, type: integrationType } }) }}>X</Button>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </StripedTable>
                    </Card.Body>
                </Card>
            )
        })
    }

    editedIntegration(): any | undefined {
        const integrationType = this.state.addIntegrationType
        const integrationId = this.state.editIntegrationId
        const configurations = this.state.integrations
        if (integrationType === undefined) { return }
        if (integrationId === undefined) { return }
        if (configurations === undefined) { return }
        return (configurations[integrationType] || {})[integrationId] || {}
    }

    render() {
        return (
            <div>
                {this.state.copied ? <Alert variant="success">Integration URL copied to clipboard.</Alert> : null}
                <br /><br />
                {
                    this.state.addIntegrationType ? (
                        <RuntimeIntegrationEdit
                            role={this.props.role}
                            integrationType={this.state.addIntegrationType}
                            existingIntegrationId={this.state.editIntegrationId}
                            existingIntegration={this.editedIntegration()}
                            additionalElements={additionalElements(this.state.addIntegrationType)}
                            staticElements={staticElements(this.state.addIntegrationType)}
                            editComplete={() => { this.setState({ addIntegrationType: undefined, editIntegrationId: undefined }) }}
                        />
                    ) : (
                        <DropdownButton
                            variant="primary"
                            title="Add integration"
                            id="add_integration"
                            onSelect={(event: any) => {
                                this.setState({ addIntegrationType: event as RuntimeIntegrationType })
                            }}
                        >
                            {
                                availableRuntimeIntegrations().map(integrationKey => {
                                    return <MenuItem key={integrationKey} eventKey={integrationKey}>Add {integrationName(integrationKey).toLowerCase() || "-"} integration</MenuItem>
                                })
                            }
                        </DropdownButton>
                    )
                }

                {
                    this.state.deleteRequest ? (
                        <Modal show={true} key="b">
                            <Modal.Header>
                                <Modal.Title>Delete integration</Modal.Title>
                            </Modal.Header>

                            <Modal.Body>Are you certain that you wish to delete the integration?</Modal.Body>

                            <Modal.Footer>
                                <Button onClick={() => { this.setState({ deleteRequest: undefined }) }}>Cancel</Button>
                                <Button variant="danger" onClick={async () => { await this.deleteIntegration(this.state.deleteRequest) }}>Delete</Button>
                            </Modal.Footer>
                        </Modal>
                    ) : null
                }

                <br /><br /><br />
                {this.renderExistingIntegrations()}
            </div>
        )
    }
}

export default withRole(RuntimeIntegrations)
